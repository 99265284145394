import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptor } from './services/interceptorService';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';
import { AuthGuardGuard } from './services/auth-guard.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RolesPermissionGuard } from './services/roles-permission.guard';
import { NotificationGuard } from './services/notification.guard';
import { MasterDataGuard } from './services/master-data.guard';
//import { DumpdataComponent } from './dashboard/dumpdata/dumpdata.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    //DumpdataComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPaginationModule
  ],
  providers: [
    AuthGuardGuard,
    RolesPermissionGuard,
    NotificationGuard,
    MasterDataGuard,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
