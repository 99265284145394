import { Injectable } from '@angular/core';
import { BehaviorSubject , Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../config';
import { environment } from 'src/environments/environment'; 
import { element } from 'protractor';
import { Router } from '@angular/router';
import { debug } from 'console';
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  public toggle = new BehaviorSubject(false);
  public baseUrl = environment.baseUrl;
  public redirectUrl : any = environment.redirectUrl; //--added by shikha on (1-Nov-23)
 
  // public productTypesObj = {};
  // public productTypes =  [
  //   {description : "Detergent Powder", value : "Detergent"},
  //   {description : "Detergent Cake", value : "Cake"},
  //   {description : "Bathing Bar/Soap", value : "BathingBarSoap"},
  //   {description : "Hand Wash", value : "HandWash"},
  //   {description : "DishWash Bar", value : "DishWashBar"},
  //   {description : "DishWash Liquid", value : "DishWashLiquid"}
  // ]


  public productTypesObj = {};
  public productTypes =  [
    {description : "Detergent Powder", value : "DetergentPowder"},
    {description : "Detergent Cake", value : "DetergentCake"},
    {description : "Bathing Bar/Soap", value : "BathingBarSoap"},
    {description : "Hand Wash", value : "HandWash"},
    {description : "DishWash Bar", value : "DishWashBar"},
    {description : "DishWash Liquid", value : "DishWashLiquid"}
  ]


  public productTypesRawObj = {};
  public productTypesRaw =  [
    {description : "Material", value : "Material"}
  ]

  public productCategoriesObj = {};
  public productCategories =  [
    {description : "Raw Material", value : "RawMaterial"},
    {description : "Semi Finished Goods", value : "SemiFinishedGoods"},
    {description : "Finished Goods", value : "FinishedGoods"},
  ]
    
  public marketSampleCategoriesObj = {};
   
  public marketSampleCategories =  [
    {description : "Quality Complaint", value : "QualityComplaint"},
    {description : "Market Sample", value : "MarketSample"}
  ]
  public sourceTypes = [
    {description : "Quality Complaint", value : "QualityComplaint"},
    {description : "Market Sample", value : "MarketSample"},
    {description : "Factory", value : "Factory"}
  ]

  public sampleSourceObj = {};
  public sampleSource =  [
    {description : "Business Partner", value : "BusinessPartner"},
    {description : "Former Business Partner", value : "FormerBusinessPartner"},
    {description : "Retailer", value : "Retailer"},
    //{description : "RSPL-BD", value : "RSPL-BD"},
    {description : "RSPLBD", value : "RSPLBD"}
  ]
  
  public complaintSourceObj = {};
  public complaintSource =  [
    {description : "Consumer", value : "Consumer"},
    {description : "Business Partner", value : "BusinessPartner"},
    {description : "Former Business Partner", value : "FormerBusinessPartner"},
    {description : "Retailer", value : "Retailer"}
  ]
  
  public brandObj={};
  public brands=[
    {description : "RSPL", value : "RSPL"},
    //{description : "RSPL-BD", value : "RSPLBD"},
    {description : "RSPLBD", value : "RSPLBD"},
    {description : "Competitor", value : "Competitor"}
  ]


  public resultTypeObj={};
  public resultType=[
    {description : "Pass", value : "Pass"},
    {description : "Failed", value : "Failed"},
    {description : "NA", value : "NA"}
  ]

  public brandObjs = {};
  public brandss = [];

  public productCategoriesObjs = {};
  public productCategoriess = [];

  public testResultss = [];

  public states = this.getStates();
  constructor(private http : HttpClient,private router : Router) {
    this.productCategories.map(el=> this.productCategoriesObj[el.value]=el.description);
    this.marketSampleCategories.map(el=> this.marketSampleCategoriesObj[el.value]=el.description);
    this.sampleSource.map(el=> this.sampleSourceObj[el.value]=el.description);
    this.complaintSource.map(el=> this.complaintSourceObj[el.value]=el.description);
    //this.brands.map(el=> this.brandObj[el.value]=el.description);
    this.resultType.map(el=> this.resultTypeObj[el.value]=el.description);

    this.getBrands();
  }

   setTokenLocalStorage(token){
    localStorage.setItem('token' , token);
   }

   setTokenSessionStorage(token){ 
    sessionStorage.setItem('token' , token);
   }
   login(params) {
    
    return this.http.get("https://intranet.rspl.net.in/IntranetAPI/api/Employee/EmployeeLogin",{params : params});
  }
 
  getBrands() {
    this.http.get(`${this.baseUrl}${config.sample}/${config.samplebrandddl}`).subscribe((res: any) => {
      
      this.brandss = res;
      this.brandss.map(el => this.brandObjs[el.id] = el.name);
    });
  }

  getCategories(brand) {
    return this.http.get(`${this.baseUrl}${config.sample}/${config.samplecategoryddl}/${brand}`)
  }

  getProductType(prodcategory) {
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.producttypeddl}/${prodcategory}`)
  }

  getProductMaterials(brand,Material) {
    
    return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleproductddl}/${brand}/${Material}`)
  }

  getTestResults() {
    return this.http.get(`${this.baseUrl}${config.sample}/${config.testresultddl}`)
  }


  getStates(){
    return this.http.get('../../assets/json/states.json');
  }

  getUser(searchTerm , isActive, pageNumber , pageSize , sortColumn , sortDirection){
    return this.http.get(`${this.baseUrl}${config.user}/${config.getUserDataWithRoles}?searchTerm=${searchTerm}&isActive=${isActive}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`)
  }

  downloadUser(fromat , searchTerm){
    return this.http.get(`${this.baseUrl}${config.user}/${config.download}?format=${fromat}&searchTerm=${searchTerm}` , { responseType: 'blob', observe: 'response' })
  }

  getUserWithoutRole(searchTerm , pageNumber , pageSize , sortColumn , sortDirection){
    return this.http.get(`${this.baseUrl}${config.user}/${config.getUserWithoutRoles}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`)
  }

  getUserRoleById(userId){
    // return this.http.get(`${this.baseUrl}${config.user}/${config.getUserProfileById}?UserId=96614`)
    return this.http.get(`${this.baseUrl}${config.user}/${config.getUserProfileById}?UserId=${userId}`)

  }

  getUserProfileId(userId){
    
    return this.http.get(`${this.baseUrl}${config.user}/${userId}`)
  }

  getRoleModulePermissions(){
    return this.http.get(`${this.baseUrl}${config.roleModulePermissions}`)
  }

  addRoleModulePermission(payload){
    return this.http.post(`${this.baseUrl}${config.roleModulePermissions}` , payload)

  }

  updateRoleModulePermission(payload){
    return this.http.post(`${this.baseUrl}${config.roleModulePermissions}/${config.updateRoleModulePermissions}` , payload)

  }

  insertOrUpdatePermissions(payload){

    if(payload){
      payload = payload.map(item =>{
        if(item['name']){
          delete item['name'];
        }
         return item;
      })
    }
    return this.http.post(`${this.baseUrl}${config.roleModulePermissions}/${config.insertOrUpdatePermissions}` , payload)

  }


  getRoleModulePermissionsById(roleId){
    return this.http.get(`${this.baseUrl}${config.roleModulePermissions}/${config.getByRoleId}?roleId=${roleId}`)
  }

  getRoles(){
    return this.http.get(`${this.baseUrl}${config.roles}`)
    
  }

  saveUserRoles(payload){
    return this.http.post(`${this.baseUrl}${config.userRoles}` , payload)
  }

  updateUserRoles(payload){
    return this.http.post(`${this.baseUrl}${config.userRoles}/${config.updateUserRoles}` , payload)
  }

  saveUser(payload){
    return this.http.post(`${this.baseUrl}${config.user}` , payload)
  }

  searchUserWithRoles(value){
    return this.http.get(`${this.baseUrl}${config.user}/${config.searchUserWithRoles}?searchTerm=${value}`);
  }

  searchUserWithoutRoles(value){
    return this.http.get(`${this.baseUrl}${config.user}/${config.searchUserWithoutRoles}?searchTerm=${value}`);
  }

  // Test Management
  getTestCollections(searchTerm , pageNumber , pageSize , sortColumn , sortDirection){
    // return this.http.get(`${this.baseUrl}${config.test}`)
    return this.http.get(`${this.baseUrl}${config.test}/${config.getTests}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`)
  }

  

  saveTestCollections(payload){
    return this.http.post(`${this.baseUrl}${config.test}`, payload)
  }

  viewTestCollections(id){
    return this.http.get(`${this.baseUrl}${config.test}/${id}`)
  }

  exportTestCollections(fromat , searchTerm){
    return this.http.get(`${this.baseUrl}${config.test}/${config.download}?format=${fromat}&searchTerm=${searchTerm}` , { responseType: 'blob', observe: 'response' })
  }

  // testDownload(){

  // }


  // Sample collection 
  getSampleCollection(){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}`);
  }

  getFactorySamples(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getSampleFactories}`,{params : filter});
  }

  getFactorySamplesResult(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getSampleFactoriesResult}`,{params : filter});
  }

  getFactorySamplesUser(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getSampleFactoriesUser}`,{params : filter});
  }

  getFactorySampleStatus(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getFactorySampleStatus}`,{params : filter});
  }
  getFactoryRoutineSample(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getFactoryRoutineSample}`,{params : filter});
  }
  getTestResultStatus(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getTestResultsStatus}`,{params : filter});
  }

  getMarketSampleReport(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReport}`,{params : filter});
  }
  
  getMarketSampleFactorywiseReport(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReportFactoryWise}`,{params : filter});
  }

  getQualityComplaintsReport(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getQualityComplaintReport}`,{params : filter});
  }

  getBrandwiseReport(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReportBrandWise}`,{params : filter});
  }

  searchSampleCollection(value){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}/${config.searchSample}?searchTerm=${value}`);
  }

  addSampleCollection(payload){
    
    return this.http.post(`${this.baseUrl}${config.sampleFactories}/${config.createFactorySampleDetails}` , payload);
  }

  updateSampleFactoryMarketCollection(payload){
    
    return this.http.post(`${this.baseUrl}${config.sampleFactories}/${config.updateSampleFactoryMarket}` , payload);
  }

  viewSampleCollection(id){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${id}`);
  }
  viewSampleCollectionImage(id){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${id}/images`);
  }

  viewSampleCollectionMarketImage(id){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${id}/images`);
  }

  addMarketSampleCollection(payload){
    return this.http.post(`${this.baseUrl}${config.sampleDetails}/${config.createMarketSampleDetails}` , payload);
  }

  getSampleImage(payload){
    return this.http.post(`${this.baseUrl}${config.sampleFactories}/${config.sampleImage}` , payload);
  }

  productIdByName(productname){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getProductIdByName}/${productname}`);
  }


  // Master Management 

  // Product 

  getProduct(searchTerm? ,pageNumber? , pageSize? ,  sortColumn? , sortDirection?){
    if(searchTerm || pageNumber || pageSize ||  sortColumn || sortDirection){
      return this.http.get(`${this.baseUrl}${config.product}/${config.getProducts}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);

    }else{
      return this.http.get(`${this.baseUrl}${config.product}`);
    }
  }

  getProductByFilter(filter? : any){
    return this.http.get(`${this.baseUrl}${config.product}/${config.getProductByCategoryAndBrandName}`,{params : filter});
  }

  getLastTestDetails(filter: any){
    return this.http.get(`${this.baseUrl}${config.test}/${config.getLastTestDetailByProductNameAndBrandName}`,{params : filter});
  }

  searchProduct(value){
    return this.http.get(`${this.baseUrl}${config.product}/${config.searchProducts}?searchTerm=${value}`);
  }

  downloadProduct(fromat , searchTerm){
    return this.http.get(`${this.baseUrl}${config.product}/${config.download}?format=${fromat}&searchTerm=${searchTerm}` , { responseType: 'blob', observe: 'response' })

  }

  deleteProduct(id){
    return this.http.delete(`${this.baseUrl}${config.product}/${id}`);

  }
  getProductSKU(id){
    return this.http.get(`${this.baseUrl}${config.productSKUs}/${config.getProductSKUByProductId}?ProductId=${id}`);
  }  

  // Lab

  getLab(searchTerm? ,pageNumber? , pageSize? ,  sortColumn? , sortDirection?){
    if(searchTerm || pageNumber || pageSize ||  sortColumn || sortDirection){
      return this.http.get(`${this.baseUrl}${config.lab}/${config.getLabs}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);   
    }else{
      return this.http.get(`${this.baseUrl}${config.lab}`);   
    }
  }

  searchLab(value){
    return this.http.get(`${this.baseUrl}${config.lab}/${config.searchLab}?searchTerm=${value}`);
  }

  deleteLab(id){
    return this.http.delete(`${this.baseUrl}${config.lab}/${id}`);
  }

  downloadLab(fromat , searchTerm){
    return this.http.get(`${this.baseUrl}${config.lab}/${config.download}?format=${fromat}&searchTerm=${searchTerm}` , { responseType: 'blob', observe: 'response' })
  }
  
  // Factory

  getFactory(searchTerm? ,pageNumber? , pageSize? ,  sortColumn? , sortDirection?){
    
    if(searchTerm || pageNumber || pageSize ||  sortColumn || sortDirection){
      return this.http.get(`${this.baseUrl}${config.factory}/${config.getFactories}?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    }else{
      return this.http.get(`${this.baseUrl}${config.factory}`);
    }
  }
  getFactoryLocation(id){
      return this.http.get(`${this.baseUrl}${config.factory}/${id}`);
  }

  searchFactory(value){
    return this.http.get(`${this.baseUrl}${config.factory}/${config.searchFactories}?searchTerm=${value}`);
  }

  deleteFactory(id){
    return this.http.delete(`${this.baseUrl}${config.factory}/${id}`);
  }

  downloadFactory(fromat , searchTerm){
    return this.http.get(`${this.baseUrl}${config.factory}/${config.download}?format=${fromat}&searchTerm=${searchTerm}` , { responseType: 'blob', observe: 'response' })
  }



  // sample department

  getAllSampleDepartment(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}/${config.getSampleDepartmentData}`,{params : filter});
  }

  getAllSampleDepartmentResult(filter : any){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}/${config.getSampleDepartmentDataResult}`,{params : filter});
  }

  getAllSampleDepartmentById(id){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}/${config.getAllSamplingDepartmentDetailById}?sampleId=${id}`);
  }

  getAllSampleDepartmentByIdImage(id){
    return this.http.get(`${this.baseUrl}${config.sampleDetails}/${config.sampleDepartment}/${id}/images`);
  }

  updateSampleDepartment(payload){
    return this.http.post(`${this.baseUrl}${config.sampleDetails}/${config.updateSample}`, payload);
  }

  getAllComplaintTypes(){
    return this.http.get(`${this.baseUrl}${config.complaintTypes}`);
  }

  getModules(){
    return this.http.get(`${this.baseUrl}${config.modules}`);
  }

  loginInsertToken(){
    
    return this.http.get("https://uatintranet.rspl.net.in/AuthAPI/api/Authenticate/InsertUserToken");
  }

  loginCheckUser(){
    
    return this.http.post("https://uatintranet.rspl.net.in/AuthAPI/api/Authenticate/CheckUser",{});
  }
  
  logoutUAT(id : any) {
    
    return this.http.post("https://uatintranet.rspl.net.in/AuthAPI/api/Authenticate/LogOut/"+id,{})
  }

  logout(){
    
      let userProfile = this.getLocalUserProfile();
      if(userProfile){
        this.logoutUAT(userProfile.id).subscribe(res=>{
        });
      }
      
      localStorage.clear();
      sessionStorage.clear();
      // this.router.navigate(['']);
      window.location.href=this.redirectUrl;//modified by shikha on (1-Nov-23)
        //this.router.navigate(this.redirectUrl); 
  }

  getLocalUserProfile(){
    let userProfile = JSON.parse(localStorage.getItem('userProfile'));
      if(!userProfile){
        return false
      }
      return userProfile;
  }
  setUserProfile(res){
    
    return localStorage.setItem('userProfile' , JSON.stringify(res));
  }

  
  downloadSampleDepartmentLab(fromat , searchTerm , sampleReceivedFrom){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.downloadSampleDepartmentData}?format=${fromat}&searchTerm=${searchTerm}&sampleReceivedFrom=${sampleReceivedFrom}` , { responseType: 'blob', observe: 'response' })
  }

  downloadSampleDepartmentLabResult(fromat , searchTerm , sampleReceivedFrom){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.downloadSampleDepartmentDataResult}?format=${fromat}&searchTerm=${searchTerm}&sampleReceivedFrom=${sampleReceivedFrom}` , { responseType: 'blob', observe: 'response' })
  }

  downloadSampleDepartmentLabResult1(filter : any){
    return  this.http.get(`${this.baseUrl}${config.sample}/${config.downloadSampleDepartmentDataResult}`,{params : filter,responseType: 'blob', observe: 'response'});
    //return this.http.get(`${this.baseUrl}${config.sample}/${config.downloadSampleDepartmentDataResult}?format=${fromat}&searchTerm=${searchTerm}&sampleReceivedFrom=${sampleReceivedFrom}` , { responseType: 'blob', observe: 'response' })
  }

  sampleCollectionDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleCollectionDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  sampleResutListingDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.sampleListingDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  getFactorySampleStatusDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getFactorySampleStatusDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  getFactoryRoutineSampleDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleFactories}/${config.getFactoryRoutineSampleDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }
  getTestResultsStatusDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getTestResultsStatusDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }
  getMarketSampleReportDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReportDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  getMarketSampleReportFactoryWiseDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReportFactoryWiseDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  getQualityComplaintReportDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getQualityComplaintReportDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }

  getMarketSampleReportBrandWiseDownload(filter){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getMarketSampleReportBrandWiseDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
  }
  

  // Dashboard 

  sampleDepartmentDashboard(filter){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.getSampleDepartmentDashboardCount}`,{params : filter});
  }

  sdFactorySelectionDashboard(filter){
    return this.http.post(`${this.baseUrl}${config.sample}/${config.getSampleDepartmentDashboardFactoryCount}`,filter);
  }
  
  sdMarketStatewiseDashboard(filter){
    return this.http.post(`${this.baseUrl}${config.sample}/${config.getMarketSampleDashboardCount}`,filter);
  }
  superAdminDashboard(fromDate? , endDtoDateate?){
    if(fromDate && endDtoDateate){
      return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getAdminDashboard}?fromDate=${fromDate}&toDate=${endDtoDateate}`);
    }else{
      return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getAdminDashboard}`);
    }
  }

  centralLab(fromDate? , endDtoDateate? , status?){
    if(fromDate || endDtoDateate || status){
      return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getSampleResultCounts}?from=${fromDate}&to=${endDtoDateate}&status=${status}`);
    }else{
      return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getSampleResultCounts}`);
    }
  }
   
  getlast7Days(){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getSampleResultOf7Day}`); 
  }

  getSDTotalReceivedlast7Days(){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.getSampleDepartmentOf7DayData}`); 
  }
  
  getSDFactoryCountMonthly(){
    return this.http.get(`${this.baseUrl}${config.factory}/${config.getFactoryDataCountMonthly}`); 
  }

  getSDBrandwiseCountMonthly(){
    return this.http.get(`${this.baseUrl}${config.sample}/${config.getBrandwiseCountMonthly}`); 
  }
  
  getSDProductCategoryYearly(filter?:any){
    return this.http.get(`${this.baseUrl}${config.factory}/${config.getTotalFactoryCategoryDataYearly}`,{params : filter}); 
  }
  getSDSampleReceivedYearly(filter?:any){
    return this.http.get(`${this.baseUrl}${config.sampleMarkets}/${config.getTotalSampleReceivedYearlyData}`,{params : filter}); 
  }

  getSDBrandwiseYearly(filter?:any){
    return this.http.get(`${this.baseUrl}${config.factory}/${config.getBrandWiseYearlyData}`,{params : filter}); 
  }

  getDateWiseSubmissionData(searchText,from){
    return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getDateWiseSubmissionData}?searchText=${searchText}&sampleReceivedFrom=${from}`);
  }

  getDateWiseSampleSendToLabData(searchText,from){
    return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getDateWiseSampleSendToLabData}?searchText=${searchText}&sampleReceivedFrom=${from}`);
  }

  getMonthWiseSubmissionData(searchText,from){
    return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getMonthWiseSubmissionData}?searchText=${searchText}&sampleReceivedFrom=${from}`);
  }

  getMonthWiseSampleSendToLabData(searchText,from){
    return this.http.get(`${this.baseUrl}${config.sampleDashboards}/${config.getMonthWiseSampleSendToLabData}?searchText=${searchText}&sampleReceivedFrom=${from}`);
  }

  
  GetByProductName(productName , testCategory){
    return this.http.get(`${this.baseUrl}${config.test}/${config.GetByProductName}?productName=${productName}&testCategory=${testCategory}`);
  }

  //Notification
  getNotification(id){
    // let count : number = 0;
    // let id : any;
    // let key : any = 'roleIds&';
    // payload.forEach(element =>{
    //   count = ++count
    //   console.log('count' , count);
    // })
    return this.http.get(`${this.baseUrl}${config.notifications}?roleIds=${id}`);
  }

  getAllNotification(){
    return this.http.get(`${this.baseUrl}${config.notifications}`);
  }

  addNotification(payload : any){
    return this.http.post(`${this.baseUrl}${config.notifications}`, payload);
  }

  updateNotification(payload : any){
    return this.http.post(`${this.baseUrl}${config.notifications}/UpdateByRoleId`, payload);
  }

  // sample result


  getSampleResult(filter){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getSampleResults}`,{params: filter});
  }

  getSampleById(id: any){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${config.getBySampleId}?SampleId=${id}`);
  }

  updateSampleResult(payload){
    return this.http.post(`${this.baseUrl}${config.sampleResults}` , payload);
  }
  
  viewSampleResult(id){
    return this.http.get(`${this.baseUrl}${config.sampleResults}/${id}`);
  }


  getTestCategory(filter){
    return this.http.get(`${this.baseUrl}${config.test}/${config.getByCategory}`,{params : filter});
  }

  getDumpData(filter:any) {
    //return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleDumpData}?format=${fromat}` , { responseType: 'blob', observe: 'response' })
    // return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleCollectionDownload}`,{params : filter,responseType: 'blob', observe: 'response'});
    return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleDumpData}`,{params : filter,responseType: 'blob', observe: 'response'})
  }

  getSampleDeleteDataList(searchTerm) {
    return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleDeleteDataList}?UniqueCode=${searchTerm}`)
  }

  deleteSampleDeleteData(id,uid){
    
    return this.http.get(`${this.baseUrl}${config.sample}/${config.sampleDelete}?SampleId=${id}&DeletedBy=${uid}`);
  }

}
