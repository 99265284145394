import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardGuard } from './services/auth-guard.guard';
import { NotFoundComponent } from './not-found/not-found.component'
// import { HomeAuthGuard } from './guards/homeAuthGuard';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { LoginGuard } from './services/login-guard';

const routes: Routes = [
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) , },
    // { path: 'support', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule), canActivate:[DashboardAuthGuard]},
    { path: '', loadChildren: () => import('./user-auth/user-auth.module').then(m => m.UserAuthModule),}, //canActivate : [LoginGuard]
    { path : '**' , component : NotFoundComponent}
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    
})
export class AppRoutingModule { }
