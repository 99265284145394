import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserAuthService } from "./user-auth.service";
import { tap } from "rxjs/operators";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private userAuthService : UserAuthService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest;
    
    if( localStorage.getItem('token')) {
      let token = localStorage.getItem('token');
      
      updatedRequest  = request.clone({
            headers: request.headers.set('Authorization', token)
        });
    } else {
      
      updatedRequest   = request.clone(); 
    }

    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          //logging the http response to browser's console in case of a success
          // if (event instanceof HttpResponse) {
          //   console.log("api call success :", event);
          // }
        },
        error => {
          //logging the http response to browser's console in case of a failuer
          // if (event instanceof HttpResponse) {
          //   console.log("api call error :", event);
          // }
        }
      )
    );
  }
}