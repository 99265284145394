// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: "https://sampling.rspl.net.in/SamplingAPI/api/",
  //baseUrl: "https://uat.rspl.net.in/SamplingPortal/api/",
  //baseUrl: "https://localhost:44319/api/",
  redirectUrl: "https://intranet.rspl.net.in/"

};

