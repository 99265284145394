import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MasterDataGuard implements CanActivate {
  public permission : any  = '';
  constructor( private router : Router ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.permission = JSON.parse(localStorage.getItem('userPermission'))
      if(this.permission.rolePermissions[5].canView){
        return true;
      }else{
        this.router.navigateByUrl('')
        return false;
      }
  }
  
}
