export const config = {
    user : 'Users',
    getUserProfileById : 'GetUserProfileById',
    roleModulePermissions : 'RoleModulePermissions',
    updateRoleModulePermissions : 'RoleModulePermissions',
    insertOrUpdatePermissions : 'InsertOrUpdatePermissions',
    getByRoleId : 'GetByRoleId',
    userRoles : 'UserRoles',
    updateUserRoles : 'UpdateUserRole',
    getUserWithRoles : 'GetUserWithRole',
    getUserWithoutRoles : 'GetUserDataWithoutRoles',
    searchUserWithRoles : 'SearchUserWithRoles',
    searchUserWithoutRoles : 'SearchUserWithoutRoles',
    getUserDataWithRoles : 'GetUserDataWithRoles',
    getUserDataWithoutRoles : 'GetUserDataWithoutRoles',
    roles : 'Roles',
    test : 'Tests',
    getLastTestDetailByProductNameAndBrandName : 'GetLastTestDetailByProductNameAndBrandName',
    getTests : 'GetTests',
    modules : 'Modules',
    sampleDetails :  "Samples",
    getFactorySample : 'GetFactorySamples',
    sampleFactories :  "SampleFactories",
    getSampleFactories : 'GetSampleFactories',
    getSampleFactoriesResult : 'GetSampleFactoriesResult',
    getSampleFactoriesUser : 'GetSampleFactoriesUser',
    getFactorySampleStatus : "GetFactorySampleStatus",
    getFactorySampleStatusDownload : "GetFactorySampleStatusDownload",
    getFactoryRoutineSampleDownload : "getFactoryRoutineSampleDownload",
    getFactoryRoutineSample : "GetFactoryRoutineSample",
    images :  "images",
    download : 'Download',
    sampleImage : 'SampleImages',
    searchSample : "SearchSample",
    createFactorySampleDetails : "CreateFactorySample",
    createMarketSampleDetails : "CreateMarketSample",
    product : "Products",
    getProducts: 'GetProducts',
    searchProducts : 'SearchProducts',
    productSKUs : 'ProductSKUs',
    getProductByCategory : 'GetProductByCategory',
    getProductByCategoryAndBrandName : 'GetProductByCategoryAndBrandName',
    getProductSKUByProductId : 'GetProductSKUByProductId',
    lab : "Labs",
    getLabs : 'GetLabs',
    searchLab : 'searchLabs',
    factory : "Factories",
    getFactories : 'GetFactories',
    searchFactories : 'SearchFactories',
    getAllSamplingDepartmentDetails : 'GetAllSamplingDepartmentDetails',
    getSampleDepartmentData : 'getSampleDepartmentData',
    getSampleDepartmentDataResult : 'GetSampleDepartmentDataResult',
    getAllSamplingDepartmentDetailById : 'GetAllSamplingDepartmentDetailById',
    sampleDepartment : 'SampleDepartment',
    updateSample : 'UpdateSample',
    downloadSampleDepartmentData : 'DownloadSampleDepartmentData',
    downloadSampleDepartmentDataResult : 'DownloadSampleDepartmentDataResult',
    sample : 'Samples',
    updateSampleFactoryMarket : 'UpdateFactoryMarketSample',
    //sampleddl : 'SampleDDL',
    samplebrandddl : 'SampleBrandDDL',
    samplecategoryddl : 'SampleCategoryDDL',
    producttypeddl : 'ProductTypeDDL',
    testresultddl : 'TestResultDDL',
    sampleproductddl : 'SampleProductDDL',
    sampleDashboards : 'SampleDashboards',
    sampleCollectionDownload : "SampleCollectionDownload",
    sampleReceivers : 'SampleReceivers',
    getSampleDepartmentDashboardData : 'GetSampleDepartmentDashboardData',
    getSampleDepartmentDashboardCount : 'GetSampleDepartmentDashboardCount',
    getSampleDepartmentDashboardFactoryCount : 'GetSampleDepartmentDashboardFactoryCount',
    getMarketSampleDashboardCount : "GetMarketSampleDashboardCount",
    getSampleDepartmentOf7DayData : "GetSampleDepartmentOf7DayData",
    getFactoryDataCountMonthly : "GetFactoryDataCountMonthly",
    getBrandwiseCountMonthly : "GetBrandwiseCountMonthly",
    getTotalFactoryCategoryDataYearly : "GetTotalFactoryCategoryDataYearly",
    getTotalSampleReceivedYearlyData : "GetTotalSampleReceivedYearlyData",
    getBrandWiseYearlyData : "GetBrandWiseYearlyData",
    getAdminDashboard : 'GetAdminDashboard',
    GetByProductName : 'GetByProductName',
    notifications : 'Notifications',
    sampleResults : 'SampleResults',
    getTestResultsStatus : "GetTestResultsStatus",
    sampleListingDownload : "SampleListingDownload",
    getTestResultsStatusDownload : "GetTestResultsStatusDownload",
    getSampleResults : 'GetSampleResults',
    getBySampleId : 'GetBySampleId',
    getSampleResultWithStatus : 'GetSampleResultWithStatus',
    getSampleResultCounts : "GetSampleResultCounts",
    getSampleResultOf7Day : "GetSampleResultOf7Day",
    getDateWiseSubmissionData : 'GetDateWiseSubmissionData',
    getDateWiseSampleSendToLabData : 'GetDateWiseSampleSendToLabData',
    getMonthWiseSubmissionData : 'GetMonthWiseSubmissionData',
    getMonthWiseSampleSendToLabData : 'GetMonthWiseSampleSendToLabData',
    getByCategory : 'GetByCategory',
    complaintTypes : 'ComplaintTypes',
    sampleMarkets : 'SampleMarkets',
    getMarketSampleReport : "GetMarketSampleReport",
    getMarketSampleReportDownload : "GetMarketSampleReportDownload",
    getMarketSampleReportBrandWise : "GetMarketSampleReportBrandWise",
    getMarketSampleReportFactoryWise : "GetMarketSampleReportFactoryWise",
    getQualityComplaintReport : "GetQualityComplaintReport",
    getMarketSampleReportFactoryWiseDownload : "GetMarketSampleReportFactoryWiseDownload",
    getMarketSampleReportBrandWiseDownload : "GetMarketSampleReportBrandWiseDownload",
    getQualityComplaintReportDownload : "GetQualityComplaintReportDownload",

    sampleDumpData : 'SampleDumpData',
    sampleDeleteDataList : 'SampleDeleteList',
    sampleDelete : 'SampleDelete',

    getProductIdByName : 'ProductIdByName'
}
