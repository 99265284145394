import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionGuard implements CanActivate {

  public permission : any  = '';
  constructor( private router : Router ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.permission = JSON.parse(localStorage.getItem('userPermission'))
      let access = 'canView';
      if(route.data.access){
        access = route.data.access;
      }
      if(this.permission && route.data.moduleId && this.permission.rolePermissions[route.data.moduleId] && this.permission.rolePermissions[route.data.moduleId][access]){
        return true;
      }
      else if(this.permission.roleName=='Admin'){
        return true;
      }
      else{
        this.router.navigateByUrl('')
        return false;
      }
  }
  
}
